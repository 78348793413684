import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  effect,
  ElementRef,
  inject,
  input,
} from '@angular/core';
import { NgClass } from '@angular/common';
import { RouterLink } from '@angular/router';

import { UserActions } from '@store/user';

import { ICrackergroupSkeleton } from '@interfaces';
import { TJsonLd } from '@models/interfaces/seo';

import { JsonLdComponent } from '@core/components/common/json-ld/json-ld.component';
import { ReactiveComponent } from '@core/components/_base/reactive.component';

import { BUILDVERSION } from '@lib/tokens';

@Component({
  standalone: true,
  selector: 'dsp-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgClass, RouterLink, JsonLdComponent],
})
export class FooterComponent extends ReactiveComponent {
  public $groups = input<ICrackergroupSkeleton[]>([], { alias: 'groups' });

  public jsonLd: TJsonLd;
  public visible = false;
  public buildInfo: string;
  public collection: { label: string; items: ICrackergroupSkeleton[] }[] = [];

  private highlightedLetter: string = null;
  private buildVersion = inject(BUILDVERSION);
  private el = inject(ElementRef);
  private cd = inject(ChangeDetectorRef);

  constructor() {
    super();
    this.buildBuildInfo();

    effect(() => {
      if (this.$groups()?.length) {
        this.buildCollection();
        this.buildJsonLd();
        this.cd.markForCheck();
      }
    });
  }

  public showDialog(subject: string): void {
    this.store.dispatch(UserActions.showContactForm({ subject }));
  }

  private buildBuildInfo(): void {
    const { timestamp, version } = this.buildVersion;
    const date = new Intl.DateTimeFormat('en-US').format(new Date(timestamp));
    this.buildInfo = `v${version}, ${date}`;
    this.cd.markForCheck();
  }

  public setHighlightLetter(letter: string): void {
    if (letter === this.highlightedLetter) {
      return;
    }

    this.highlightedLetter = letter;
    this.el.nativeElement.setAttribute('data-letter', letter);
    this.cd.markForCheck();
  }

  private buildCollection(): void {
    this.collection = this.$groups().reduce(
      (p, c) => {
        let container = p.find(pack => pack.label === c.letter);

        if (!container) {
          container = { label: c.letter, items: [c] };
          return [...p, container];
        }

        container.items.push(c);
        return p;
      },
      [] as typeof this.collection
    );
  }

  private buildJsonLd(): void {
    this.jsonLd = {
      '@context': 'https://schema.org',
      '@type': ['SiteNavigationElement', 'ItemList'],
      itemListElement: this.$groups().map((item, idx) => ({
        '@type': 'WebPage',
        position: idx,
        name: item.handle ? `${item.name} [${item.handle}]` : item.name,
        url: `https://www.docsnyderspage.com${item.path}`,
      })),
    };
  }
}
